import logo from './logo.svg';
import { Layout } from './components/Layout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { MetaMisha } from './components/MetaMisha';
import './components/misha_add.css';

function RedirectToExternal() {
  useEffect(() => {
    window.location.href = 'https://misha-miroshnikov.webflow.io';
  }, []);

  return null; // No UI, just the redirection
}

function App() {
  return (
    <div className="App b">
      <Router>
        <Routes>
          <Route path="/" element={<RedirectToExternal />} />
          <Route path="/metamisha" element={<MetaMisha />} />
        </Routes>
      </Router>

      {/* <Layout /> */}
    </div>
  );
}

export default App;
