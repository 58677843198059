import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';

export function MetaMisha(props) {
  const [state, setState] = useState();
  return (
    <>
      <div className="_fv _fcc _f100" style={{ background: '#170201', color: '#f55e45', padding: '4em', minHeight: '100vh' }}>

        <a href="https://open.spotify.com/artist/4clDetn6VCiJHiGqhE0itu?si=1dEpVCXhQ9WbF7cFD1tLqw" target='_blank'>
          <img src="https://i1.sndcdn.com/avatars-BBzBzCKe4X67OHO6-0HFuyw-t500x500.jpg" alt="" />
        </a>

        <h1>METAMISHA</h1>
        <h3 className="_fwrap _fcc">
          <a href="https://open.spotify.com/artist/4clDetn6VCiJHiGqhE0itu?si=1dEpVCXhQ9WbF7cFD1tLqw" target='_blank'>Spotify</a><span> ✦ </span>
          <a href="https://music.apple.com/us/artist/metamisha/1763411072" target='_blank'>Apple Music</a><span> ✦ </span>
          <a href="https://soundcloud.com/metamisha" target='_blank'>SoundCloud</a><span> ✦ </span>
          <a href="https://www.instagram.com/chillmisha" target='_blank'>Instagram</a><span> ✦ </span>
          <a href="https://www.youtube.com/@stonerpark" target='_blank'>Youtube</a>
        </h3>
        <div />
        <div />
        <hr style={{ width: '80%', borderColor: '#f55e45' }} />
        <div />
        <div />

        <div className="_tc">
          METAMISHA is an artist moniker of Misha Miroshnikov
        </div>

      </div>
    </>
  )
}